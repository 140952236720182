<template>
  <b-container
    fluid
  >
    <div class="d-flex justify-content-end mb-3 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-container fluid>
      <b-row>
        <b-col
          cols="12"
          md="8"
          class="px-1"
        >
          <b-form-group
            label="Search"
            label-for="filter_qap_search"
            label-class="font-weight-bolder"
          >
            <b-input
              id="filter_qap_search"
              v-model="tableQAOfPayees.filter.search"
              type="text"
              placeholder="search here"
              debounce="1000"
              autocomplete="off"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="6"
          md="2"
          class="px-1"
          order="1"
          order-md="2"
        >
          <b-form-group
            label="Fiscal Year"
            label-for="filter_fiscal_year"
            label-class="font-weight-bolder"
          >
            <b-select
              id="filter_fiscal_year"
              v-model="tableQAOfPayees.filter.fiscal_year"
              debounce="1000"
              :options="filterFiscalYears"
              :disabled="state.busy || fetching.filterOptions"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="6"
          md="2"
          class="px-1"
          order="1"
          order-md="2"
        >
          <b-form-group
            label="Report Period"
            label-for="filter_report_period"
            label-class="font-weight-bolder"
          >
            <b-select
              id="filter_report_period"
              v-model="tableQAOfPayees.filter.report_period"
              debounce="1000"
              :options="filterReportPeriods"
              :disabled="state.busy || fetching.filterOptions"
            />
          </b-form-group>
        </b-col>

        <b-col
          v-if="tableQAOfPayees.filter.fiscal_year !== 'All' && tableQAOfPayees.filter.report_period !== 'All'"
          cols="12"
          class="px-1 mb-2"
          order="3"
        >
          <b-overlay
            rounded
            opacity="0.1"
            spinner-small
            class="d-inline"
            spinner-variant="warning"
            :show="exporter.busy"
          >
            <json-excel
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="btn btn-success btn-sm"
              :fetch="excelProvider"
              :before-generate="onStartExcelExport"
              :before-finish="onFinishExcelExport"
              :fields="exporter.excel.fields"
              :escape-csv="exporter.excel.escape"
              :type="exporter.excel.type"
              :header="exporter.excel.header"
              :footer="exporter.excel.footer"
              :name="excelFileName"
              worksheet="Quarterly Alphalist of Payees"
            >
              {{ exporter.busy ? 'Exporting' : 'Export As Excel' }}
            </json-excel>
          </b-overlay>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          class="px-1"
        >
          <b-table
            ref="tableQAOfPayees"
            hover
            small
            striped
            bordered
            responsive
            show-empty
            :stacked="isMobile()"
            :per-page="tableQAOfPayees.perPage"
            :current-page="tableQAOfPayees.currentPage"
            :items="tableQAOfPayeesProvider"
            :fields="tableQAOfPayees.fields"
            :sort-by.sync="tableQAOfPayees.sortBy"
            :sort-desc.sync="tableQAOfPayees.sortDesc"
            :sort-direction="tableQAOfPayees.sortDirection"
            :filter="tableQAOfPayees.filter"
            :filter-included-fields="tableQAOfPayees.filterOn"
            :busy="tableQAOfPayees.busy"
          >
            <template #cell(index)="row">
              {{ tableQAOfPayees.currentPage * tableQAOfPayees.perPage - tableQAOfPayees.perPage + (row.index + 1) }}
            </template>

            <template #cell(income_payment)="row">
              <div
                class="text-md-nowrap text-md-right"
                style="min-width: 100px;"
              >
                {{ row.value }}
              </div>
            </template>

            <template #cell(tax_amount)="row">
              <div
                class="text-md-nowrap text-md-right"
                style="min-width: 100px;"
              >
                {{ row.value }}
              </div>
            </template>

            <template #cell()="row">
              <div class="text-md-nowrap">
                {{ row.value }}
              </div>
            </template>

            <template #table-busy>
              <div class="text-center py-5">
                <b-icon
                  icon="stopwatch"
                  font-scale="5"
                  animation="cylon"
                />
                <p class="h3 py-2">
                  <strong>Loading . . .</strong>
                </p>
              </div>
            </template>

          </b-table>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mt-1 mb-2 px-1"
        >
          <b-select
            v-model="tableQAOfPayees.perPage"
            :options="tableQAOfPayees.pageOptions"
            class="w-100 w-md-25"
          />
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
        >
          <b-pagination
            v-model="tableQAOfPayees.currentPage"
            pills
            last-number
            first-number
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
            :total-rows="tableQAOfPayees.totalRows"
            :per-page="tableQAOfPayees.perPage"
          />
        </b-col>
      </b-row>
    </b-container>

  </b-container>
</template>

<script>
import QUERY from 'lodash'
import { core } from '@/config/pluginInit'
import { STAQAP, SSharedList } from '@/services'
import formatter from '@/mixins/formatter'
import Ripple from 'vue-ripple-directive'
import misc from '@/mixins/misc'

export default {
  name: 'TaReportsQAPayees',

  middleware: ['auth', 'ta'],

  directives: {
    Ripple
  },

  mixins: [formatter, misc],

  metaInfo: () => ({
    title: 'Tax Codes'
  }),

  data () {
    return {
      state: {
        busy: false,
        editing: false
      },
      fetching: {
        filterOptions: false
      },
      lists: {
        fiscalYears: [],
        reportPeriods: []
      },
      tableQAOfPayees: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: null,
          fiscal_year: 'All',
          report_period: 'All'
        },
        filterOn: [],
        busy: false,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 1, key: 'fiscal_year.fiscal_name', label: 'Fiscal Year' },
          { mobile: 2, key: 'report_period' },
          { mobile: 3, key: 'atc' },
          { mobile: 4, key: 'bp_name', label: 'Partner Name' },
          { mobile: 5, key: 'bp_tin', label: 'TIN' },
          { mobile: 6, key: 'bp_trade_name', label: 'Trade Name' },
          { mobile: 7, key: 'bp_corporation_name', label: 'Corporation Name' },
          { mobile: 8, key: 'bp_first_name', label: 'First Name' },
          { mobile: 9, key: 'bp_middle_name', label: 'Middle Name' },
          { mobile: 10, key: 'bp_last_name', label: 'Last Name' },
          { mobile: 11, key: 'income_payment', formatter: this.numberFormatter },
          { mobile: 12, key: 'tax_amount', formatter: this.numberFormatter },
          { mobile: 13, key: 'tax_rate', formatter: this.numberFormatter }
        ].sort(this.scaleOnMobile)
      },
      exporter: {
        busy: false,
        pdf: null,
        excel: {
          header: [],
          escape: false,
          type: 'xls',
          fields: {
            '#': {
              field: 'page_number'
            },
            'Fiscal Year': {
              field: 'fiscal_year.fiscal_name'
            },
            'Report Period': {
              field: 'report_period'
            },
            'Alphanumeric Tax Code': {
              field: 'atc'
            },
            'Partner Name': {
              field: 'bp_name'
            },
            TIN: {
              field: 'bp_tin'
            },
            'Trade Name': {
              field: 'bp_trade_name'
            },
            'Corporation Name': {
              field: 'bp_corporation_name'
            },
            'First Name': {
              field: 'bp_first_name'
            },
            'Middle Name': {
              field: 'bp_middle_name'
            },
            'Last Name': {
              field: 'bp_last_name'
            },
            'Income Payment': {
              field: 'income_payment'
            },
            'Tax Amount': {
              field: 'tax_amount',
              callback: taxAmount => this.numberFormatter(taxAmount) || 0.00
            },
            'Tax Rate': {
              field: 'tax_rate',
              callback: taxRate => this.numberFormatter(taxRate) || 0.00
            }
          },
          footer: []
        }
      }
    }
  },

  computed: {
    excelFileName () {
      const fiscalYear = QUERY.find(this.lists.fiscalYears, { value: this.tableQAOfPayees.filter.fiscal_year })

      return `Quarterly Alphalist of Payees ( ${this.tableQAOfPayees.filter.report_period} of ${fiscalYear?.text} )`
    },

    filterFiscalYears () {
      return [{ value: 'All', text: 'All' }].concat(this.lists.fiscalYears)
    },

    filterReportPeriods () {
      return [{ value: 'All', text: 'All' }].concat(this.lists.reportPeriods)
    }
  },

  mounted () {
    core.index()
    this.getFilterOptions()
  },

  methods: {

    async tableQAOfPayeesProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableQAOfPayees.busy = true
      return await STAQAP.get(
        this.objectToUrl({
          page: currentPage,
          per_page: perPage,
          sort: sortBy,
          sort_desc: sortDesc,
          filter_text: filter.search,
          filter_fiscal_year: filter.fiscal_year,
          filter_report_period: filter.report_period
        })
      ).then(({ data }) => {
        this.tableQAOfPayees.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tableQAOfPayees.busy = false
      })
    },

    async getFilterOptions () {
      this.fetching.filterOptions = true
      return new Promise(resolve => {
        SSharedList.getCustomList(
          this.toArrayListParam([
            'fiscal_years',
            'report_periods'
          ])
        ).then(({ data }) => {
          this.lists.fiscalYears = data.fiscal_years?.map(
            fiscalYear => ({
              value: fiscalYear.id,
              text: fiscalYear.fiscal_name
            })
          )
          this.lists.reportPeriods = data.report_periods
          resolve(data)
        })
      }).finally(() => {
        this.fetching.filterOptions = false
      })
    },

    async excelProvider () {
      if (this.exporter.busy) {
        return null
      }
      this.exporter.busy = true
      return await STAQAP.export(
        this.objectToUrl({
          filter_text: this.tableQAOfPayees.filter.search,
          filter_fiscal_year: this.tableQAOfPayees.filter.fiscal_year,
          filter_report_period: this.tableQAOfPayees.filter.report_period
        })
      ).then(({ data }) => {
        if (data.items.length <= 0) {
          this.swalInvalid(
            'No available data to be Exported'
          )
        }
        return data.items.map((item, i) => {
          item.page_number = Number(this.tableQAOfPayees.currentPage) * Number(this.tableQAOfPayees.perPage) - Number(this.tableQAOfPayees.perPage) + (Number(i) + 1)
          return item
        })
      }).catch(() => []).finally(() => {
        this.exporter.busy = false
      })
    },

    onStartExcelExport () {
      if (this.exporter.busy) {
        return null
      }

      const fiscalYear = QUERY.find(this.lists.fiscalYears, { value: this.tableQAOfPayees.filter.fiscal_year })
      const companyName = process.env?.VUE_APP_VOUCHER_COMPANY_NAME || ''

      this.exporter.excel.header = [
        'QUARTERLY ALPHALIST OF PAYEES',
        companyName,
        `${this.tableQAOfPayees.filter.report_period} of ${fiscalYear?.text}`,
        '&nbsp;'
      ]
    },

    onFinishExcelExport () {
    }

  }
}
</script>
